import React, { createContext, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";

const ThemeContext = createContext();

export const SystemThemeProvider = ({ children }) => {
  const getSystemTheme = () => {
    return window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  };

  const getInitialUserPreference = () => {
    const savedPreference = localStorage.getItem("user-theme");
    return savedPreference ? savedPreference : "system";
  };

  const [userPreference, setUserPreference] = useState(getInitialUserPreference);
  const [theme, setTheme] = useState(() => (userPreference === "system" ? getSystemTheme() : userPreference));

  useEffect(() => {
    const handleSystemThemeChange = (e) => {
      if (userPreference === "system") {
        setTheme(e.matches ? "dark" : "light");
      }
    };

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addEventListener("change", handleSystemThemeChange);

    return () => mediaQuery.removeEventListener("change", handleSystemThemeChange);
  }, [userPreference]);

  useEffect(() => {
    localStorage.setItem("user-theme", userPreference);
    setTheme(userPreference === "system" ? getSystemTheme() : userPreference);
  }, [userPreference]);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={[theme, userPreference, setUserPreference]}>
      {children}
    </ThemeContext.Provider>
  );
};

SystemThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme should use in SystemThemeProvider. check your code again");
  }
  return context;  // Return as a tuple: [theme, userPreference, setUserPreference]
};
