import { useState, useEffect } from "react";
import axiosInstance from "../HelperAxios/axios";

const useCountry = () => {
  const [country, setCountry] = useState(
    localStorage.getItem("country") || "ae"
  );

  const fetchCountry = () => {
    axiosInstance
      .get("setting")
      .then((res) => {
        const country = res.data?.country || "ae";
        localStorage.setItem("country", country);
        setCountry(country);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  return country.toLowerCase();
};

export default useCountry;
