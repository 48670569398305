import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useState } from "react";
import FilterInput from "../FilterInput/filterInput.component";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import PropTypes from "prop-types";
import StrinLocalized from "../../Language/language.lang";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Fields from "../../components/Inputs/Fields";
const LogsFilterDrawer = ({
  children,
  onSearch,
  onReset,
  isOpen,
  setIsOpen,
}) => {

  const searchHandler = () => {
    onSearch();
    setIsOpen(false);
  };

  const resetHandler = () => {
    onReset();
    setIsOpen(false);
  };

  return (
    <SwipeableDrawer
      open={isOpen}
      anchor="bottom"
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      disableSwipeToOpen={false}
      // className={`${setIsOpen && "backdrop-blur-sm"}`}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          overflowY: "revert",
        },
      }}
    >
      <div className="flex justify-center py-3">
        <div className="w-8 h-[6px] bg-[#5b5a5a] rounded-lg"></div>
      </div>
      <section className="p-5">
        <Fields>{children}</Fields>

        {/* Buttons */}
        <div className="flex justify-center gap-3 pt-7 w-full">
          <Button
            size="large"
            variant="contained"
            sx={{ borderRadius: "10px" }}
            onClick={searchHandler}
            startIcon={<SearchIcon />}
            fullWidth
          >
            {StrinLocalized.Search}
          </Button>
          <Button
            size="large"
            variant="outlined"
            sx={{ borderRadius: "10px" }}
            onClick={resetHandler}
            startIcon={<RestartAltIcon />}
            fullWidth
          >
            {StrinLocalized.Reset}
          </Button>
        </div>
      </section>
    </SwipeableDrawer>
  );
};

LogsFilterDrawer.propTypes = {
  children: PropTypes.any,
  onSearch: PropTypes.func,
  onReset: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default LogsFilterDrawer;
