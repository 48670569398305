import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import React from "react";
import useResponsive from "../hooks/useResponsive";

export const SideBarContext = createContext();

const useMobileWidth = (onMobileWidth) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Function to check window width and set state
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
        onMobileWidth(); // Call the specified function when in mobile range
      } else {
        setIsMobile(false);
      }
    };

    // Add event listener on mount
    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [onMobileWidth]);

  return isMobile;
};

const SideBarProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [width, setWidth] = useState("300px");
  const responsive = useResponsive();
  const closeWidth = responsive.isUnderMd ? '0px' : '80px';
  const openWidth = responsive.isUnderMd ? '0px' : "300px";

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setWidth(closeWidth);
      setIsOpen(false);
    }
  }, []);
  const toggle = () => {
    setIsOpen((prev) => !prev);
    console.log("toggle");
    if (isOpen) setWidth(closeWidth);
    else setWidth(openWidth);
  };

  useEffect(() => {
    if (responsive.isUnderMd) {
      setIsOpen(false);
    }
  }, [responsive.isUnderMd])

  return (
    <SideBarContext.Provider value={{ isOpen, toggle, width }}>
      {children}
    </SideBarContext.Provider>
  );
};
export default SideBarProvider;

export const useSideBar = () => {
  return useContext(SideBarContext);
};

SideBarProvider.propTypes = {
  children: PropTypes.node,
};
