import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material";
import { useTheme } from "../../hooks/useTheme";

const MuiThemeProvider = ({ children }) => {
  const [theme] = useTheme();
  const rtl = localStorage.getItem("rtl");
  const lang = localStorage.getItem("lang");

  let fontFamily = [];
  switch (lang) {
    case "FA":
    case "AR":
      fontFamily.push("Vazirmatn");
      break;
    case "EN":
    case "TR":
      fontFamily.push("DM Sans");
      break;
    default:
      fontFamily.push("sans-serif");
  }

  const muiTheme = createTheme({
    direction: rtl === "true" ? "rtl" : "ltr",
    typography: {
      fontFamily,
      fontWeightBold: 700,
      button: {
        textTransform: "none",
      },
    },
    palette: {
      mode: theme,
      primary: { main: theme === 'dark' ? "#cb3195" : "#701951" },
      secondary: { main: "#505050" },
    },
    components: {
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: theme === "dark" && "#323232cc",
          },
        },
      },
    },
  });

  return <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>;
};

MuiThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MuiThemeProvider;
