import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Proptypes from "prop-types";
import moment from "moment-jalaali";
import { AdapterMomentJalaali } from "@mui/x-date-pickers/AdapterMomentJalaali";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTheme } from "../../hooks/useTheme";

const DateMange = ({ onChange, value, label, showTime }) => {
  moment.loadPersian({ dialect: "persian-modern" });
  const language = localStorage.getItem("lang");
  const [themeDark] = useTheme();

  // Custom theme to fix arrow direction
  const theme = createTheme({
    components: {
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          button: {
            transform: language === "FA" ? "rotate(180deg)" : "none",
          },
        },
      },
      MuiMultiSectionDigitalClock: {
        styleOverrides: {
          root: {
            flexDirection: language === "FA" ? "row-reverse" : "row",
          },
        },
      },
      ...(themeDark === 'dark' && {
        MuiTextField: {
          styleOverrides: {
            root: {
              "& .MuiFormLabel-root": {
                color: "#cdcdcd"
              },
              "& .MuiInputBase-root": {
                color: "#fff",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#444",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#fff",
              },
              "& .MuiSvgIcon-root": {
                color: "#bdbdbd",
              },
            },
          },
        },
      })
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {language === "FA" ? (
        <LocalizationProvider dateAdapter={AdapterMomentJalaali}>
          <DemoContainer
            sx={{ paddingTop: 0, overflow: "visible" }}
            components={[showTime ? "DateTimePicker" : "DatePicker"]}
          >
            {showTime ? (
              <DateTimePicker
                sx={{ width: "100%" }}
                slotProps={{ textField: { size: "small" } }}
                label={label}
                value={value ? moment(value) : undefined}
                onChange={onChange}
              />
            ) : (
              <DatePicker
                sx={{ width: "100%" }}
                slotProps={{ textField: { size: "small" } }}
                label={label}
                value={value ? moment(value) : undefined}
                onChange={onChange}
              />
            )}
          </DemoContainer>
        </LocalizationProvider>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            sx={{ paddingTop: 0, overflow: "visible" }}
            components={[showTime ? "DateTimePicker" : "DatePicker"]}
          >
            {showTime ? (
              <DateTimePicker
                sx={{ width: "100%" }}
                slotProps={{ textField: { size: "small" } }}
                label={label}
                value={value ? dayjs(value) : undefined}
                onChange={onChange}
              />
            ) : (
              <DatePicker
                sx={{ width: "100%" }}
                slotProps={{ textField: { size: "small" } }}
                label={label}
                value={value ? dayjs(value) : undefined}
                onChange={onChange}
              />
            )}
          </DemoContainer>
        </LocalizationProvider>
      )}
    </ThemeProvider>
  );
};

export default DateMange;

DateMange.propTypes = {
  onChange: Proptypes.func.isRequired,
  value: Proptypes.string,
  label: Proptypes.string,
  showTime: Proptypes.bool, // پارامتر جدید برای تعیین نمایش ساعت
};
