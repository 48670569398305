import { Autocomplete, Button, Chip, TextField } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useState } from "react";
import StrinLocalized from "../../../Language/language.lang";

const MultiSelect = ({ input }) => {
  const { handleSubmit, control } = useForm();
  const [selectedValue, setSelectedValue] = useState(null);
  const handleAddNewOption = () => {
    !input.existedoption?.includes(selectedValue) &&
      selectedValue !== null &&
      input.change(selectedValue);
  };
  return (
    <Stack sx={{ minWidth: "250px", width: "100%" }} gap={2}>
      <Box sx={{ width: "100%" }} display="flex" gap={2}>
        <Controller
          sx={{ minWidth: "250px" }}
          control={control}
          name={input?.title}
          defaultValue={null}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Autocomplete
              sx={{ minWidth: "250px" }}
              disablePortal
              id="combo-box-demo"
              options={input?.options}
              getOptionLabel={(option) => StrinLocalized[option] || ""}
              value={selectedValue}
              size="small"
              onChange={(e, data) => {
                setSelectedValue(data);
              }}
              renderInput={(params) => (
                <TextField {...params} label={input.title} error={!!error} />
              )}
            />
          )}
        />
        <Button
          sx={{ alignSelf: "start" }}
          onClick={handleAddNewOption}
          variant="contained"
        >
          {StrinLocalized.AddOption}
        </Button>
      </Box>
      <Stack direction="row" gap={1} flexWrap="wrap">
        {input?.existedoption?.map((item) => (
          <Chip
            size="medium"
            sx={{ alignSelf: "start" }}
            key={item}
            label={StrinLocalized[item]}
            onDelete={() => input.handleDeleteOption(item)}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default MultiSelect;

MultiSelect.propTypes = {
  input: PropTypes.shape({
    title: PropTypes.string,
    className: PropTypes.string,

    type: PropTypes.string,
    name: PropTypes.string,
    identifier: PropTypes.string,
    change: PropTypes.func,
    blur: PropTypes.func,
    options: PropTypes.arrayOf(String),
    existedoption: PropTypes.arrayOf(String),
    handleDeleteOption: PropTypes.func,
  }),

  // onCustomSelect: PropTypes.object,
};
