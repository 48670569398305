import getCookie from "../Utils/getCookie";
import { API_URL } from "./const";
import axios from "axios";

const baseURL = API_URL;

const axiosInstance = axios.create({
  baseURL,
});

const language = localStorage.getItem("lang") || "en";

axiosInstance.interceptors.request.use(async function (config) {
  const token = getCookie("token-siraj");
  config.headers.Authorization = `Bearer ${token}`;
  config.headers["x-api-key"] = `58b53900-9ab8-4367-b328-4a17f6818833`;
  config.headers["accept-language"] = language.toLowerCase();
  // config.headers["Cache-Control"] = "no-cache";
  // config.headers["Pragma"] = "no-cache";
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const token = getCookie("token-siraj");
    const status = error.response.status;
    const redirectDestination = error?.response?.data?.redirect;
    const url = error.config.url;
    if (
      status === 403 &&
      redirectDestination &&
      url !== "user/registerFCM" &&
      token
    ) {
      // if (redirectDestination &&  ) {
      window.location.replace(redirectDestination);
      // }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
