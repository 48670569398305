/* eslint-disable react/prop-types */
// Mui
import {
  Button,
  Stack,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
} from "@mui/material";
// Language
import strings from "../../Language/language.lang";
// Component
import DateMange from "../datePicker/DateMange";
// React Hook Form
import { useForm, Controller } from "react-hook-form";
// React Router
import { useSearchParams } from "react-router-dom";
// React
import { useEffect, useState } from "react";
// Utils
import { getAll } from "../../Utils/getAll";
import useResponsive from "../../hooks/useResponsive";
import TuneIcon from "@mui/icons-material/Tune";
import LogsFilterDrawer from "./LogsFilterDrawer";

export default function LogsFilter({ search, reset }) {
  const [searchParams] = useSearchParams();
  const responsive = useResponsive();
  const [isOpen, setIsOpen] = useState(false);

  const [userList, setUserList] = useState();

  const getSearchParams = () => {
    const from = searchParams.get("from");
    const to = searchParams.get("to");
    const user = searchParams.get("user");

    return {
      from,
      to,
      user,
    };
  };

  const { from, to, user } = getSearchParams();

  const {
    handleSubmit,
    control,
    reset: resetFormValue,
  } = useForm({
    defaultValues: {
      user: JSON.parse(user) ?? "",
      from: from ?? "",
      to: to ?? "",
    },
  });

  useEffect(() => {
    getAll(setUserList, "user/select-list");
  }, []);

  const resetHandler = () => {
    resetFormValue();
    reset();
  };

  return responsive.isUnderMd ? (
    <div>
      <Button
        size="large"
        variant="outlined"
        sx={{ borderRadius: "10px", p: 1, width: 10, mt: 4 }}
        onClick={() => setIsOpen(true)}
      >
        <TuneIcon />
      </Button>

      <LogsFilterDrawer
        onSearch={handleSubmit(search)}
        onReset={resetHandler}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        component="form"
      >
        <Controller
          name="user"
          control={control}
          render={({ field: { onChange, ...props } }) => (
            <Autocomplete
              onChange={(_, data) =>
                onChange({
                  id: data?.id,
                  name: data?.name,
                })
              }
              {...props}
              size="small"
              options={userList ?? []}
              getOptionLabel={(option) => option.name ?? ""}
              getOptionKey={(option) => option.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={strings.UserId}
                  defaultValue="pojkoij"
                />
              )}
            />
          )}
        />
        <Controller
          name="from"
          control={control}
          render={({ field }) => (
            <DateMange label={strings.DateFrom} {...field} />
          )}
        />
        <Controller
          name="to"
          control={control}
          render={({ field }) => (
            <DateMange label={strings.DateTo} {...field} />
          )}
        />
      </LogsFilterDrawer>
    </div>
  ) : (
    <Stack
      direction="row"
      component="form"
      onSubmit={handleSubmit(search)}
      mt={3}
      gap={2}
      sx={{ display: "flex", flexWrap: "wrap" }}
    >
      <Controller
        name="user"
        control={control}
        render={({ field: { onChange, ...props } }) => (
          <Autocomplete
            onChange={(_, data) =>
              onChange({
                id: data?.id,
                name: data?.name,
              })
            }
            {...props}
            size="small"
            options={userList ?? []}
            getOptionLabel={(option) => option.name ?? ""}
            getOptionKey={(option) => option.id}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={strings.UserId}
                defaultValue="pojkoij"
              />
            )}
          />
        )}
      />

      <Controller
        name="from"
        control={control}
        render={({ field }) => (
          <DateMange label={strings.DateFrom} {...field} />
        )}
      />

      <Controller
        name="to"
        control={control}
        render={({ field }) => <DateMange label={strings.DateTo} {...field} />}
      />
      <Button variant="contained" type="submit">
        {strings.Search}
      </Button>
      <Button variant="outlined" onClick={resetHandler}>
        {strings.Reset}
      </Button>
    </Stack>
  );
}
