import { useEffect, useState } from "react";
import { getAll } from "../../Utils/getAll";
import { Box, Stack, Typography, Divider, IconButton } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StringLocalized from "../../Language/language.lang";
import sideBarData from "./sidebarData/sideBar.data";
import { useNavigate, useLocation } from "react-router-dom";
import { useSideBar } from "../../Context/SideBarProvider";
import SignoutIcon from "../../Icons/sidebarIcons/Logout/LogoutIcon";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useRtl } from "../../Context/RtlProvider";
import { useSearch } from "../../Context/SearchProvider";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import axiosInstance from "../../HelperAxios/axios";
import { Buffer } from "buffer";
import { useTheme } from "../../hooks/useTheme";
import { IoCloseSharp } from "react-icons/io5";
import useResponsive from "../../hooks/useResponsive";

export default function SideBar() {
  const [user, setUser] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, width, toggle } = useSideBar();
  const signtOut = useSignOut();
  const { rtl } = useRtl();
  const { search } = useSearch();
  const currentUser = useAuthUser();
  const [loadLang, setLoadLang] = useState(false);
  const [logo, setLogo] = useState();
  const [subscriptionDate, setSubscriptionDate] = useState("");
  const [theme] = useTheme();
  const responsive = useResponsive();
  
  useEffect(() => {
    getAll(setUser, "user/get-user-profile");
    if (currentUser.subscription?.image) {
      axiosInstance
        .get(`assets/${currentUser.subscription?.image}`, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          const base64Image = Buffer.from(res.data, "binary").toString(
            "base64"
          );
          setLogo(`data:image/jpeg;base64,${base64Image}`);
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  if (user) {
    localStorage.setItem("permissions", JSON.stringify(user.role?.permissions));
    localStorage.setItem("timeZone", user.timeZone);
  }
  const actvieStyle = {
    bgcolor: theme === 'dark' ? "#a8a8a8a2" : "white",
    color: theme === 'dark' ? "#000" : "#701951",
    svg: { fill: theme === 'dark' ? "#000" :"#701951" },
  };

  const handleSingOut = () => {
    signtOut();
    navigate("/login");
  };

  function isDatePassed(dateString) {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    return inputDate < currentDate;
  }

  useEffect(() => {
    axiosInstance
      .get("subscription/my")
      .then((res) => {
        setSubscriptionDate(res?.data?.expirationTime);
        localStorage.setItem("subscription", res?.data?.subDomain);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem("userInfo", JSON.stringify(user));
    }
  }, [user]);
    
  return (
    <Box
      component="nav"
      sx={{
        width: { md: isOpen ? '300px' : '80px' , xs: isOpen ? '100vw' : '0px' },
        transition: "0.2s ease ",
        height: "100vh",
        padding: isOpen ? "20px" : "20px 0",

        textAlign: "center",
        margin: "0 auto",
        overflowY: "scroll",
        overflowX: "hidden",
        // position: {md: "fixed", xs: 'absolute'},
        position: 'fixed',
        left: 0,
        bgcolor: theme === 'dark' ? "#252425" : "#701951",
        zIndex: '999'
      }}
    >
      <Box sx={{ display: { md: 'none', xs: 'flex' }, justifyContent: 'end',  }} className="flex justify-end md:hidden">
        <IconButton sx={{ color: '#fff' }} onClick={toggle}>
          <IoCloseSharp />
        </IconButton> 
      </Box>
      <Stack
        gap={1}
        direction="column"
        justifyContent="center"
        alignItems="center"
        marginBottom="25px"
        width={1}
        dir="ltr"
      >
        {user && (
          <img style={{ width: "40px" }} src={logo} alt="" />
        )}
        {isOpen && (
          <Typography
            sx={{ userSelect: "none" }}
            color="white"
            fontWeight="500"
            component="span"
          >
            {currentUser.subscription?.name}
          </Typography>
        )}
      </Stack>
      <Divider variant="fullWidth" color="#D6D5D5" />

      <List sx={{ height: "100%" }}>
        {user?.role &&
          sideBarData.map((sideBarItem, index) => {
            if (
              sideBarItem.subMenues &&
              hasCommonChild(user.role.permissions, sideBarItem.subMenues)
            ) {
              return (
                <Box key={index}>
                  {sideBarItem.subMenues
                    .filter((item) => {
                      if (search !== "") {
                        if (
                          StringLocalized[item.title]
                            .trim()
                            .toLowerCase()
                            .includes(search.trim().toLowerCase())
                        ) {
                          console.log(item);
                          if (item) {
                            console.log("yes");
                          }
                          return item;
                        }
                      } else {
                        return item;
                      }
                    })
                    .map((subItem, index2) => {
                      if (
                        user.role.permissions.find(
                          (item) => item.id == subItem.access
                        ) ||
                        user.role.permissions.find(
                          (item) =>
                            item.id === "menu:all" ||
                            subItem.access === "dashboard"
                        )
                      ) {
                        return (
                          <ListItem
                            disablePadding
                            key={index2}
                            sx={{
                              color: "white",

                              fontSize: "14px",
                              marginTop: "10px",
                              width: "min",
                              "&:last-child": {
                                borderBottom: "1px solid white",
                                paddingBottom: "10px",
                              },
                            }}
                          >
                            <ListItemButton
                              onClick={() => {
                                if (responsive.isUnderMd) toggle();
                                if (isDatePassed(subscriptionDate))
                                  navigate("/subscription");
                                else navigate(subItem.path);
                              }}
                              sx={{
                                ...(location.pathname === subItem.path && {
                                  ...actvieStyle,
                                }),
                                borderRadius: isOpen ? "25px" : "0px",
                                gap: "5px",
                                padding: "10px",

                                justifyContent: isOpen
                                  ? "space-between"
                                  : "center",
                                "&:hover": {
                                  bgcolor: theme === 'dark' ? "#a8a8a8a2" : "white",
                                  borderRadius: isOpen ? "25px" : "0px",
                                  color: theme === 'dark' ? "#242425" : "#701951",
                                  svg: {
                                    fill: theme === 'dark' ? "#242425" : "#701951",
                                  },
                                },
                              }}
                            >
                              <ListItemIcon sx={{ minWidth: "32px" }}>
                                {subItem.icon}
                              </ListItemIcon>
                              {isOpen && (
                                <ListItemText
                                  primary={StringLocalized[subItem.title]}
                                />
                              )}
                            </ListItemButton>
                          </ListItem>
                        );
                      }
                    })}
                </Box>
              );
            } else {
              if (
                user.role.permissions.find(
                  (item) => item.id == sideBarItem.access
                ) ||
                user.role.permissions.find(
                  (item) =>
                    item.id === "menu:all" || sideBarItem.access === "dashboard"
                )
              ) {
                return (
                  <ListItem
                    disablePadding
                    key={index}
                    sx={{
                      color: "white",
                      fontSize: "14px",
                      marginTop: "5px",
                      width: "min",
                    }}
                  >
                    <ListItemButton
                      onClick={() => {
                        if (responsive.isUnderMd) toggle();
                        navigate(sideBarItem.path)
                      }}
                      sx={{
                        ...(location.pathname === sideBarItem.path && {
                          ...actvieStyle,
                        }),
                        borderRadius: isOpen ? "25px" : "0px",
                        gap: "5px",
                        padding: "10px",

                        justifyContent: isOpen ? "space-between" : "center",
                        "&:hover": {
                          bgcolor: theme === 'dark' ? "#a8a8a8a2" : "white",
                          borderRadius: isOpen ? "25px" : "0px",
                          color: theme === 'dark' ? "#242425" : "#701951",
                          svg: {
                            fill: theme === 'dark' ? "#242425" : "#701951",
                          },
                        },
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "32px" }}>
                        {sideBarItem.icon}
                      </ListItemIcon>
                      {isOpen && (
                        <ListItemText
                          primary={StringLocalized[sideBarItem.title]}
                        />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              }
            }
          })}

        <ListItem
          disablePadding
          sx={{
            color: "white",
            fontSize: "14px",
            width: "min",
            paddingBottom: "40px",
            paddingTop: "10px",
          }}
        >
          <ListItemButton
            onClick={handleSingOut}
            sx={{
              borderRadius: isOpen ? "25px" : "0px",
              gap: "5px",
              padding: "10px",

              justifyContent: isOpen ? "space-between" : "center",
              "&:hover": {
                bgcolor: theme === 'dark' ? "#a8a8a8a2" : "white",
                borderRadius: isOpen ? "25px" : "0px",
                color: theme === 'dark' ? "#242425" : "#701951",
                svg: { fill: theme === 'dark' ? "#242425" : "#701951" },
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: "32px" }}>
              <SignoutIcon />
            </ListItemIcon>
            {isOpen && <ListItemText primary={StringLocalized.Logout} />}
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}

function hasCommonChild(array1, array2) {
  for (let i = 0; i < array1.length; i++) {
    for (let y = 0; y < array2.length; y++) {
      if (array1[i].access == array2[y].id) {
        return true;
      }
    }
  }
  return false;
}
SideBar.propTypes;
