import { styled } from "@mui/system";

export const StyledButton = styled("div")(
  ({ width, height, marginRight, marginLeft, right }) => ({
    width: width,
    marginRight: marginRight,
    marginLeft: marginLeft,
    "& .css-1s2u09g-control": {
      background: "#fff",
      // borderRadius: "14px",
      height: height,
      "& .css-1rhbuit-multiValue": {
        background: "#E2E2E2",
        // borderRadius: "8px",
      },
    },
    "& svg": {
      right: right ? `${right}%` : "90%",
      fontSize: "2rem",
      color: "#000",
    },
  })
);
