import { IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import { FiSun, FiMoon } from "react-icons/fi";
import { MdSettingsBrightness } from "react-icons/md";
import StrinLocalized from "../../Language/language.lang";
import { FaCheck } from "react-icons/fa";
import { useTheme } from "../../hooks/useTheme";

const items = [
  { id: "light", value: StrinLocalized.Light, icon: <FiSun size={17} /> },
  {
    id: "system",
    value: StrinLocalized.System,
    icon: <MdSettingsBrightness size={17} />,
  },
  { id: "dark", value: StrinLocalized.Dark, icon: <FiMoon size={17} /> },
];

const ThemeSetting = () => {
  const [theme, userPreference, setUserPreference] = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const handleChangeTheme = (val) => {
    setUserPreference(val);
    setIsOpen(false);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".theme-dropdown")) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <section className="relative flex justify-center theme-dropdown">
      <IconButton onClick={() => setIsOpen((prev) => !prev)}>
        <FiSun size={21} />
      </IconButton>
      <div
        className={`absolute w-36 divide-y overflow-hidden border rounded-xl shadow-2xl duration-200 ${
          isOpen ? "top-full opacity-100 pointer-events-auto" : "top-20 opacity-0 pointer-events-none"
        } ${
          theme === "dark"
            ? "bg-zinc-700 border-gray-500 divide-gray-500"
            : "bg-white"
        }`}
      >
        {items.map((item) => (
          <button
            key={item.id}
            onClick={() => handleChangeTheme(item.id)}
            className={`w-full flex start ps-8 items-center gap-2 p-2 duration-100 cursor-pointer ${
              theme === "dark" ? "hover:bg-gray-500" : "hover:bg-gray-100"
            } ${
              userPreference === item.id
                ? theme === "dark"
                  ? "bg-zinc-600"
                  : "bg-gray-50"
                : ""
            }`}
          >
            <p>{item.icon}</p>
            <p>{item.value}</p>
            {userPreference === item.id && (
              <FaCheck size={10} className={theme === "dark" ? "text-blue-400" : "text-blue-600"} />
            )}
          </button>
        ))}
      </div>
    </section>
  );
};

export default ThemeSetting;
