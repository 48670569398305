import axiosInstance from "../HelperAxios/axios";

export const getAll = async (setList, url) => {
  const response = await axiosInstance
    .get(`${url}`)
    .then((res) => {
      setList(res.data);
      // console.log(res.data);
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return response;
};
