import PropTypes from "prop-types";
import Select from "../Inputs/SelectBox/SelectBox.component";
import MultiSelect from "../formInput/multiselect/MultiSelect";
import { filterTypes } from "./filterInputData/filterInput.data";
import "./style/filterInput.style.css";
import { Button, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import DateMange from "../datePicker/DateMange";

const FilterInput = ({ filter }) => {
  const inputsSize = 140;
  const height = 40;

  switch (filter.type) {
    case filterTypes.selectBox:
      return (
        <Select
          options={filter.options}
          placeHolder={filter.title}
          width={inputsSize}
          height={height}
          marginRight={"4px"}
          marginLeft={"4px"}
          onCustomSelect={filter.change}
          identifier={filter.identifier}
          onBlur={filter.blur}
          value={filter.value}
        />
      );
    case filterTypes.reactSelect:
      return (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={filter.options}
          renderOption={(props, item) => (
            <li {...props} key={item.key}>
              {item.label}
            </li>
          )}
          value={filter.value}
          size="small"
          sx={{ width: { md: 250, xs: '100%'} }}
          onBlur={filter.blur}
          onChange={filter.change}
          renderInput={(params) => (
            <TextField {...params} label={filter.title} />
          )}
        />
        // <ReactSelect
        //   className={"react-select"}
        //   name="color"
        //   isSearchable
        //   value={filter.value}
        //   options={filter.options}
        //   placeholder={filter.title}
        //   onChange={filter.change}
        // />
      );
    case filterTypes.textBox:
      return (
        <TextField
          id={filter.name}
          placeHolder={filter.title}
          label={filter.title}
          variant="outlined"
          size="small"
          onChange={filter.change}
          onBlur={filter.blur}
          value={filter.value}
        />
        // <TextBox
        //   id={filter.name}
        //   placeHolder={filter.title}
        //   width={inputsSize}
        //   height={height}
        //   onChange={filter.change}
        //   value={filter.value}
        // />
      );
    case filterTypes.datePicker:
      return (
        // <Date
        //   placeHolder={filter.title}
        //   onCustomSelect={(e) => filter.change(e[0])}
        //   width={inputsSize}
        //   height={36}
        //   value={filter.value[0].day ? filter.value : ""}
        // />
        // <LocalizationProvider dateAdapter={AdapterDayjs}>
        //   <DemoContainer
        //     sx={{ paddingTop: 0, overflow: "visible" }}
        //     components={["DatePicker"]}
        //   >
        //     <DatePicker
        //       slotProps={{ textField: { size: "small" } }}
        //       value={filter.value}
        //       onBlur={filter.blur}
        //       onChange={filter.change}
        //       label={filter.title}
        //     />
        //   </DemoContainer>
        // </LocalizationProvider>
        <DateMange
          value={filter.value}
          onChange={filter.change}
          label={filter.title}
        />
      );
    case filterTypes.multiSelect:
      return <MultiSelect input={filter} />;
    case filterTypes.text:
      return (
        <p style={{ width: inputsSize, height: height }}>{filter.title}</p>
      );
    case filterTypes.button:
      return (
        <Button
          variant="outlined"
          onClick={filter.change}
          sx={{
            margin: "0 4px 8px 8px",
            fontSize: "14px",
            height: "40px",
            borderRadius: "0",
          }}
        >
          {filter.title}
        </Button>
      );
    default:
      throw new Error("please write a type which consist in filter type");
  }
};

FilterInput.propTypes = {
  filter: PropTypes.shape({
    title: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    identifier: PropTypes.string,
    value: PropTypes.any,
    change: PropTypes.func,
    blur: PropTypes.func,
    options: PropTypes.arrayOf(String),
  }).isRequired,
  // onChange: PropTypes.any,
  // onCustomSelect: PropTypes.object,
};
export default FilterInput;
