import { useState, useEffect } from "react";

const useProfileInfo = () => {
  const [profileInfo, setProfileInfo] = useState(() => {
    return JSON.parse(localStorage.getItem("userInfo") || "{}");
  });

  useEffect(() => {
    const storedInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
    setProfileInfo(storedInfo);
  }, []);

  return profileInfo;
};

export default useProfileInfo;
