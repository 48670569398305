import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useResponsive = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isXLDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const mixTabletAndMobile = useMediaQuery(
    theme.breakpoints.between('xs', 'lg')
  );
  const isUnderLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isUnderMd = useMediaQuery(theme.breakpoints.down('md'));

  return {
    isMobile,
    isTablet,
    isDesktop,
    isXLDesktop,
    mixTabletAndMobile,
    isUnderLg,
    isUnderMd,
  };
};

export default useResponsive;
