import {
  Stack,
  Typography,
  Container,
  Popper,
  Fade,
  Box,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import { useRtl } from "../../Context/RtlProvider";
import StrinLocalized from "../../Language/language.lang";
import { useTheme } from "../../hooks/useTheme";

const PageHeader = ({
  title,
  subTitle,
  info,
  openGuide,
  guideInfo,
  showGuide = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { rtl } = useRtl();
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [theme] = useTheme();
  

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Container
      maxWidth="xlg"
      sx={{
        borderBottom: "1px solid #D6D5D5",
        paddingY: "20px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="column">
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="h5" color={theme === 'dark' ? '#fff' : "#505050"} fontWeight={500}>
            {title}
          </Typography>
          {info ? (
            <>
              <InfoIcon
                className={guideInfo ? guideInfo : ""}
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                fontSize="small"
                sx={{ color: theme === 'dark' ? '#cdcdcd' : "#505050" }}
              />
              <Popper
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                  maxWidth: "1000px",
                  zIndex: 1000,
                }}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Fade in={open} timeout={350}>
                  <Typography
                    sx={{
                      p: 1,
                      whiteSpace: "pre-wrap",
                      backgroundColor: "whitesmoke",
                      borderRadius: 1,
                      boxShadow: 2,
                      bgcolor: theme === 'dark' ? '#4d4d4d' : "#ffffff"
                    }}
                  >
                    {info}
                  </Typography>
                </Fade>
              </Popper>
            </>
          ) : null}
        </Stack>
        <Typography variant="subtitle1" color="#505050" fontWeight={400}>
          {subTitle}
        </Typography>
      </Stack>
      {showGuide && (
        <>
          <Stack>
            <Button
              type="submit"
              onClick={openGuide}
              variant="contained"
              fullWidth
            >
              {StrinLocalized.Guide}
            </Button>
          </Stack>
        </>
      )}
    </Container>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  info: PropTypes.string,
  guideInfo: PropTypes.string,
  openGuide: PropTypes.func,
  showGuide: PropTypes.bool,
};

export default PageHeader;
