import StringLocalized from "../../../Language/language.lang";

import ReportsIcon from "../../../Icons/sidebarIcons/Reports/reports.icon";
import SubscriptionIcon from "../../../Icons/sidebarIcons/Subscription/SubscriptionIcon";
import UsersIcon from "../../../Icons/sidebarIcons/users/UsersIcon";
import ContactsIcon from "../../../Icons/sidebarIcons/Contacts/ContactsIcon";
import UnitsIcon from "../../../Icons/sidebarIcons/Units/UnitsIcon";
import ExtraFieldsIcon from "../../../Icons/sidebarIcons/ExtraFields/ExtraFieldsIcon";
import TypesIcon from "../../../Icons/sidebarIcons/Types/TypesIcon";
import LanguageIcon from "../../../Icons/sidebarIcons/Language/LanguageIcon";
import ProjectsIcon from "../../../Icons/sidebarIcons/Projects/ProjectsIcon";
import ReservationIcon from "../../../Icons/sidebarIcons/Reservations/ReservationsIcon";
import DashboardIcon from "../../../Icons/sidebarIcons/Dashboard/DashboardIcon";
import ContractIcon from "../../../Icons/sidebarIcons/Contract/ContractIcon";
import NegotiatesIcon from "../../../Icons/sidebarIcons/Negotiates/NegotiatesIcon";
import SettingIcon from "../../../Icons/sidebarIcons/Setting/SettingIcon";
import MessagesIcon from "../../../Icons/sidebarIcons/Messages/MessagesIcon";
import TicketsIcon from "../../../Icons/sidebarIcons/Tickets/TicketsIcon";
import FeedbackIcon from "../../../Icons/sidebarIcons/Feedback/FeedbackIcon";
import InvoicesIcon from "../../../Icons/sidebarIcons/Invoices/InvoicesIcon";
import ContactsAndItemsIcon from "../../../Icons/sidebarIcons/ContactsAndItems/ContactsAndItemsIcon";
import RolesIcon from "../../../Icons/sidebarIcons/Roles/RolesIcon";
import ReceivedFeedback from "../../../Icons/sidebarIcons/ReceivedFeedback/RecivedFeedbackIcon";
import CallmeIcon from "../../../Icons/sidebarIcons/Callme/CallmeIcon";
import SignoutIcon from "../../../Icons/sidebarIcons/Logout/LogoutIcon";
import VisitIcon from "../../../Icons/sidebarIcons/Visit/VisitIcon";
import FileManagerIcon from "../../../Icons/sidebarIcons/FileManager/FileMangaerIcon";
import ContactUsIcon from "../../../Icons/sidebarIcons/ContactUs/ContactUsIcon";
import BasicDataIcon from "../../../Icons/sidebarIcons/BasicDataIcon/BasicData.icon";
import KanbanIcon from "../../../Icons/sidebarIcons/Kanban/Kanban";
import FormMakerIcon from "../../../Icons/sidebarIcons/formMaker/FormMakerIcon";
import AddHouse from "../../../Icons/sidebarIcons/addHouse/AddHouse.icon";
import MessageTemplateIcon from "../../../Icons/sidebarIcons/MessageTemplate/MessageTemplateIcon";
import PackagesIcon from "../../../Icons/sidebarIcons/packages/PackagesIcon";
import DiscountIcon from "../../../Icons/sidebarIcons/discount/discount.icon";
import SubscriptionSettingIcon from "../../../Icons/sidebarIcons/subscriptionSetting/SubscriptionSetting";
import ScheduleIcon from "../../../Icons/sidebarIcons/Schedule/ScheduleIcon";

const SideBarData = [
  {
    title: "Dashboard",
    icon: <DashboardIcon />,
    path: "/",
    access: "dashboard",
  },
  {
    title: "Basic Data",
    icon: <BasicDataIcon />,
    subMenues: [
      {
        title: "Subscription",
        icon: <SubscriptionIcon />,
        path: "/subscriptions-list",
        access: "menu:subscription",
      },
      {
        title: "Roles",
        icon: <RolesIcon />,
        path: "/roles-list",
        access: "menu:role",
      },
      {
        title: "Negotiates",
        icon: <NegotiatesIcon />,
        path: "/negotiates-list",
        access: "menu:negotiation",
      },
      {
        title: "Kanban",
        icon: <KanbanIcon />,
        path: "/kanban",
        access: "menu:kanban",
      },
      {
        title: "Reservation",
        icon: <ReservationIcon />,
        path: "/reservation-list",
        access: "menu:reservation",
      },
      {
        title: "Contract",
        icon: <ContractIcon />,
        path: "/contracts-list",
        access: "menu:contract",
      },
      {
        title: "Tickets",
        icon: <TicketsIcon />,
        path: "/tickets-list",
        access: "menu:ticket",
      },
      {
        title: "Messages",
        icon: <MessagesIcon />,
        path: "/messages-list",
        access: "menu:message",
      },
      {
        title: "ReceivedFeedback",
        icon: <ReceivedFeedback />,
        path: "/received-feedbacks-list",
        access: "menu:recievedfeedback",
      },
      {
        title: "CallMe",
        icon: <CallmeIcon />,
        path: "/call-me-list",
        access: "menu:callme",
      },
      {
        title: "Forms",
        icon: <ContactUsIcon />,
        path: "/contact-us-list",
        access: "menu:contactus",
      },
      {
        title: "Payments",
        icon: <InvoicesIcon />,
        path: "/invoices-list",
        access: "menu:invoice",
      },
      {
        title: "VisitRequest",
        icon: <VisitIcon />,
        path: "/visit-request-list",
        access: "menu:visitrequest",
      },
    ],
  },
  {
    title: "PM",
    icon: <BasicDataIcon />,
    subMenues: [
      {
        title: "DashboardPM",
        icon: <UsersIcon />,
        path: "/dashboard-pm",
        access: "menu:dashboardpm",
      },
      {
        title: "ReservationListItem",
        icon: <ReservationIcon />,
        path: "/reservation-list-item",
        access: "menu:reservationitem",
      },
      {
        title: "Schedule",
        icon: <ScheduleIcon />,
        path: "/schedule-list",
        access: "menu:reservationitem", // TODO: access should change.
      },
      {
        title: "WorkOrders",
        icon: <UsersIcon />,
        path: "/work-oders-list",
        access: "menu:workorders",
      },
      {
        title: "Assets",
        icon: <UsersIcon />,
        path: "/assets-list",
        access: "menu:assets",
      },
      {
        title: "Parts",
        icon: <UsersIcon />,
        path: "/parts-list",
        access: "menu:parts",
      },
    ],
  },

  {
    title: "Elements",
    icon: <BasicDataIcon />,
    subMenues: [
      {
        title: "Contacts",
        icon: <ContactsIcon />,
        path: "/contacts-list",
        access: "menu:contact",
      },
      {
        title: "Users",
        icon: <UsersIcon />,
        path: "/users-list",
        access: "menu:user",
      },
      {
        title: "Units",
        icon: <UnitsIcon />,
        path: "/units-list",
        access: "menu:unit",
      },
      {
        title: "FileManager",
        icon: <FileManagerIcon />,
        path: "/file-manager",
        access: "menu:filemanager",
      },
      {
        title: "Feedbacks",
        icon: <FeedbackIcon />,
        path: "/feedbacks-list",
        access: "menu:feedback",
      },
    ],
  },
  {
    title: "Functional",
    icon: <BasicDataIcon />,
    subMenues: [
      {
        title: "Types",
        icon: <TypesIcon />,
        path: "/types-list",
        access: "menu:type",
      },
      {
        title: "FormMaker",
        icon: <FormMakerIcon />,
        path: "/form-maker",
        access: "menu:formmaker",
      },
      {
        title: "AddHouse",
        icon: <AddHouse />,
        path: "/house",
        access: "menu:property",
      },
      {
        title: "Projects",
        icon: <ProjectsIcon />,
        path: "/projects-list",
        access: "menu:project",
      },
      {
        title: "Languages",
        icon: <LanguageIcon />,
        path: "/languages-list",
        access: "menu:language",
      },
      {
        title: "ExtraFields",
        icon: <ExtraFieldsIcon />,
        path: "/extra-fields-list",
        access: "menu:extrafield",
      },
      {
        title: "MessageTemplate",
        icon: <MessageTemplateIcon />,
        path: "/message-template",
        access: "menu:messagetemplate",
      },
      {
        title: "Packages",
        icon: <PackagesIcon />,
        path: "/packages",
        access: "menu:package",
      },
      {
        title: "Discount",
        icon: <DiscountIcon />,
        path: "/discount",
        access: "menu:discount",
      },
      {
        title: "Setting",
        icon: <SettingIcon />,
        path: "/setting",
        access: "menu:setting",
      },
      {
        title: "Subscription",
        icon: <SubscriptionIcon />,
        path: "/subscription",
        access: "menu:mysubscription",
      },
      {
        title: "SiteSetting",
        icon: <SubscriptionSettingIcon />,
        path: "/subscription-setting",
        access: "menu:sitesetting",
      },
    ],
  },
  {
    title: "Deleted",
    icon: <BasicDataIcon />,
    subMenues: [
      {
        title: "ContactsLog",
        icon: <ContactsIcon />,
        path: "/list-contacts-log",
        access: "menu:contactchanges",
      },
      {
        title: "UsersLog",
        path: "/list-users-log",
        icon: <UsersIcon />,
        access: "menu:userchanges",
      },
      {
        title: "UnitsLog",
        icon: <UnitsIcon />,
        path: "/units-log-list",
        access: "menu:unitchanges",
      },
      {
        title: "DeletedUsers",
        icon: <UsersIcon />,
        path: "/deleted-users",
        access: "menu:deletedusers",
      },
      {
        title: "DeletedUnits",
        icon: <UnitsIcon />,
        path: "/deleted-units",
        access: "menu:deletedunits",
      },
      {
        title: "DeletedContacts",
        icon: <ContactsIcon />,
        path: "/deleted-contacts",
        access: "menu:deletedcontacts",
      },
    ],
  },

  // {
  //   title: StringLocalized.AccessDriver,
  //   icon: <ReportsIcon />,
  //   child: [
  //     {
  //       title: StringLocalized.IncreaseWallet,
  //       icon: <ReportsIcon />,
  //       path: "/increase-wallet",
  //     },
  //     {
  //       title: StringLocalized.MonthlyMicrotransactions,
  //       icon: <ReportsIcon />,
  //       path: "/microtransactions",
  //     },
  //   ],
  // },
  // {
  //   title: StringLocalized.DriversAccessList,
  //   icon: <ReportsIcon />,
  //   path: "/access-drivers",
  // },
  // {
  //   title: StringLocalized.FareIncreaseRouteA2,
  //   icon: <ReportsIcon />,
  //   path: "/fare-increase-route-A2",
  // },
  // {
  //   title: StringLocalized.NotificationList,
  //   icon: <ReportsIcon />,
  //   path: "/notifications",
  // },

  // {
  //   title: StringLocalized.Logout,
  //   icon: <LogoutIcon />,
  //   path: "/login",
  // },
];

export default SideBarData;
