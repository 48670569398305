import PropTypes from "prop-types";
// import Select from "@mui/material/Select";
import Select from "react-select";
// import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { StyledButton } from "./selectBoxStyles/customSelect.style";

const SelectBox = ({
  width,
  placeHolder,
  height,
  options,
  border,
  marginLeft,
  marginRight,
  right,
  background,
  value,
  defaultValue,
  onChange,
  isDisabled,
}) => {
  let rtl = localStorage.getItem("rtl");
  return (
    <StyledButton
      width={width}
      height={height}
      marginRight={marginRight ? marginRight : rtl === "true" ? 5 : 0}
      marginLeft={marginLeft ? marginLeft : rtl === "true" ? 0 : 5}
      right={right}
    >
      <Select
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            width: width ? width : "571px",
            background: background,
            border: border,
          }),
        }}
        className="basic-single"
        classNamePrefix="select"
        placeholder={placeHolder}
        isDisabled={isDisabled}
        isClearable={true}
        isRtl={rtl === "true" ? true : false}
        name="role"
        defaultValue={defaultValue}
        options={options}
        value={value}
        onChange={onChange}
      />
    </StyledButton>
  );
};

SelectBox.propTypes = {
  width: PropTypes.any,
  isDisabled: PropTypes.bool,
  placeHolder: PropTypes.string.isRequired,
  height: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.any),
  border: PropTypes.bool,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  right: PropTypes.number,
  background: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default SelectBox;
