import { useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import AuthProvider from "react-auth-kit";
import store from "./Utils/auth/store";
import SideBarProvider from "./Context/SideBarProvider";
import RtlProvider from "./Context/RtlProvider";
import SearchProvider from "./Context/SearchProvider";
import { useFCM } from "./fcm/useFcm";
import ManageRoute from "./ManageRoute";
import TimeZoneProvider from "./Context/TimeZone";
import React from "react";
import { SystemThemeProvider } from "./hooks/useTheme";

function App() {
  const { loadToken } = useFCM();

  useEffect(() => {
    try {
      loadToken();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <SystemThemeProvider>
      <RtlProvider>
        <AuthProvider store={store}>
          <SearchProvider>
            <TimeZoneProvider>
              <SideBarProvider>
                <CookiesProvider>
                  <ManageRoute />
                </CookiesProvider>
              </SideBarProvider>
            </TimeZoneProvider>
          </SearchProvider>
        </AuthProvider>
      </RtlProvider>
    </SystemThemeProvider>
  );
}

export default App;
