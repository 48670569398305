/* eslint-disable react/prop-types */
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/material";

const Fields = ({ children, noMargin }) => {
  const matches = useMediaQuery("(max-width:900px)");
  return (
    <Box
      sx={{
        width: "100%",
        marginTop: noMargin ? "0" : "35px",
        marginBottom: noMargin ? "0" : "10px",
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        flexDirection: matches ? "column" : "row", // تغییر جهت برای موبایل
        "& .MuiFormControl-root,.MuiAutocomplete-root,.MuiStack-root,& > .MuiBox-root,& .MuiFormControlLabel-root ":
          {
            flexGrow: 1,
            flexBasis: "48%",
          },
      }}
    >
      {children}
    </Box>
  );
};

export default Fields;
