import React, { useRef, useState, useEffect } from "react";
import Timezone from "../../Utils/Data/timezone.json";
import {
  LoginContainer,
  RightSide,
  FormWrapper,
} from "./loginStyles/login.style";
import { useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import LoginLeft from "../../assets/loginLeft.png";
import LoginRight from "../../assets/loginRight.png";
import LoginPhoto from "../../assets/loginBackground.jpg";
import StrinLocalized from "../../Language/language.lang";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Autocomplete, Checkbox } from "@mui/material";
import axiosInstance from "../../HelperAxios/axios";
import { API_URL } from "../../HelperAxios/const";
import MuiPhoneNumber from "mui-phone-number";
import {
  Stack,
  FormGroup,
  FormLabel,
  TextField,
  Box,
  Typography,
  Button,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ThemeProvider } from "@mui/material";
import theme from "../../Layout/layoutThemeConfig/layoutThemeConfig.theme";
import Footer from "../../components/Footer/footer.component";
import { useNavigate } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import useCountry from "../../hooks/useCountry";

const RegisterUser = () => {
  const { handleSubmit, control } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const defaultCountry = useCountry();

  const lang = searchParams.get("lang");
  const rtl = searchParams.get("rtl");

  StrinLocalized.setLanguage(lang.toUpperCase());

  const captchaRef = useRef(null);
  const showPasswordHandler = () => {
    setVisible(!visible);
  };

  const onSubmit = (data) => {
    const token = captchaRef.current.getValue();
    if (!token) {
      toast.error(StrinLocalized.CaptchaError);
      return;
    }
    const Data = {
      username: data.username,
      name: data.name,
      mobileNumber: `${data.mobileNumber}`,
      email: data.email,
      timeZone: data.timeZone?.value,
      password: data.password,
      "g-recaptcha-response": token,
    };

    toast.info(StrinLocalized.SendingData);
    axiosInstance
      .post(API_URL + "user/create/free", {
        ...Data,
        role: 2,
        status: "active",
        subscription: Number(searchParams.get("subscription")),
      })
      .then((res) => {
        toast.success(StrinLocalized.SuccessLogin);
        navigate("/login");
        captchaRef.current.reset();
      })
      .catch((err) => {
        if (typeof err.response.data.message === "string") {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.response.data.message[0]);
        }
      });
  };

  let timezoneOptions = [];
  if (Timezone) {
    Timezone.map((item) => {
      timezoneOptions.push({ value: item.value, label: item.text });
    });
  }
  return (
    <ThemeProvider theme={theme}>
      <LoginContainer
        backgroundPhoto={LoginPhoto}
        sx={{ height: "160vh", direction: rtl === "yes" ? "rtl" : "ltr" }}
      >
        <img
          style={{ position: "absolute", left: 0, bottom: 0 }}
          src={LoginLeft}
        ></img>
        <img
          style={{ position: "absolute", right: 0, bottom: 0 }}
          src={LoginRight}
        ></img>
        <Typography
          fontWeight="bold"
          variant="h3"
          sx={{
            background: "linear-gradient(#0018D4, #CF00BA)",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
            width: "600px",
            marginInline: "auto",
            padding: "30px",
          }}
        >
          {StrinLocalized.Register}
        </Typography>{" "}
        <RightSide>
          <FormWrapper>
            <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
              <Stack width="100%" gap={4}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="subtitle2" color="white">
                    {StrinLocalized.subtitleRegisterLong}
                  </Typography>
                  <Typography variant="subtitle2" color="white">
                    {StrinLocalized.subtitleRegister}
                  </Typography>
                </Box>
                <Controller
                  name="username"
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <FormGroup>
                      <FormLabel
                        sx={{ marginBottom: "5px", fontWeight: "bold" }}
                      >
                        {StrinLocalized.UserName}
                      </FormLabel>
                      <TextField
                        id="username"
                        type="text"
                        name="username"
                        onChange={onChange}
                        value={value}
                        sx={{
                          "&  .MuiInputBase-root,& .MuiTextField-root": {
                            bgcolor: "white",
                          },
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                        variant="outlined"
                        placeHolder={StrinLocalized.UserName}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <FormGroup>
                      <FormLabel
                        sx={{ marginBottom: "5px", fontWeight: "bold" }}
                      >
                        {StrinLocalized.Name}
                      </FormLabel>
                      <TextField
                        id="name"
                        type="text"
                        name="name"
                        onChange={onChange}
                        value={value}
                        sx={{
                          "&  .MuiInputBase-root,& .MuiTextField-root": {
                            bgcolor: "white",
                          },
                          "& fieldset": {
                            border: "none",
                          },
                          "& svg": { height: "1em" },
                        }}
                        variant="outlined"
                        placeHolder={StrinLocalized.Name}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="mobileNumber"
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <FormGroup>
                      <FormLabel
                        sx={{ marginBottom: "5px", fontWeight: "bold" }}
                      >
                        {StrinLocalized.Mobile}
                      </FormLabel>
                      <MuiPhoneNumber
                        defaultCountry={defaultCountry}
                        onChange={onChange}
                        value={value}
                        variant="outlined"
                        sx={{
                          "&  .MuiInputBase-root,& .MuiTextField-root": {
                            bgcolor: "white",
                          },
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <FormGroup>
                      <FormLabel
                        sx={{ marginBottom: "5px", fontWeight: "bold" }}
                      >
                        {StrinLocalized.Email}
                      </FormLabel>
                      <TextField
                        id="email"
                        type="text"
                        name="email"
                        onChange={onChange}
                        value={value}
                        sx={{
                          "&  .MuiInputBase-root,& .MuiTextField-root": {
                            bgcolor: "white",
                          },
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                        variant="outlined"
                        placeHolder={StrinLocalized.Email}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="timeZone"
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <FormGroup>
                      <FormLabel
                        sx={{ marginBottom: "5px", fontWeight: "bold" }}
                      >
                        {StrinLocalized.TimeZone}
                      </FormLabel>
                      <Autocomplete
                        id="timeZone"
                        name="timeZone"
                        options={timezoneOptions}
                        value={value}
                        onChange={(e, data) => onChange(data)}
                        sx={{
                          "&  .MuiInputBase-root,& .MuiTextField-root": {
                            bgcolor: "white",
                            borderRadius: 1,
                          },
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </FormGroup>
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { onChange, value = "" } }) => (
                    <FormGroup>
                      <FormLabel
                        sx={{ marginBottom: "5px", fontWeight: "bold" }}
                      >
                        {StrinLocalized.Password}
                      </FormLabel>
                      <TextField
                        id="password"
                        name="password"
                        type={visible ? "text" : "password"}
                        onChange={onChange}
                        value={value}
                        sx={{
                          "&  .MuiInputBase-root,& .MuiTextField-root": {
                            bgcolor: "white",
                          },
                          "& fieldset": {
                            border: "none",
                          },
                        }}
                        variant="outlined"
                        placeHolder={StrinLocalized.Password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={showPasswordHandler}
                                edge="end"
                              >
                                {visible ? (
                                  <VisibilityOff style={{ color: "#9D2778" }} />
                                ) : (
                                  <Visibility
                                    color="red"
                                    style={{ color: "#9D2778" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormGroup>
                  )}
                />{" "}
                <Box sx={{ zIndex: "999" }}>
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  />
                </Box>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    bgcolor: "#9D2778",
                    "&:hover": { bgcolor: "#9D2778" },
                  }}
                >
                  {StrinLocalized.Register}
                </Button>
              </Stack>
            </form>
          </FormWrapper>
        </RightSide>
      </LoginContainer>
      <ToastContainer rtl={false} />
      <Footer />
    </ThemeProvider>
  );
};

export default RegisterUser;
